import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService implements OnDestroy {
  public readonly QR_URL_PARAM = 'code';

  private activeQr = new BehaviorSubject<string>('');
  activeQr$ = this.activeQr.asObservable();

  private parcels = new BehaviorSubject<number | null>(null);
  parcels$ = this.parcels.asObservable();

  private photo = new BehaviorSubject<File | null>(null);
  photo$ = this.photo.asObservable();

  setActiveQr(qr: string): void {
    this.activeQr.next(this.findQrParamIfExist(qr));
  }

  setParcels(parcels: number): void {
    this.parcels.next(parcels);
  }

  setPhoto(photo: File): void {
    this.photo.next(photo);
  }

  ngOnDestroy(): void {
    this.activeQr.complete();
    this.parcels.complete();
    this.photo.complete();
  }


  private findQrParamIfExist(qr: string): string {
    try {
      const url = new URL(qr);
      return url.searchParams.get(this.QR_URL_PARAM) ?? qr;
    } catch (_) {
      return qr;
    }
  }
}
